import React, { Fragment } from "react"
import PageLayout from '../../../layouts/page-layout';
import {graphql} from "gatsby";

const Page = ({data}) => {
  return (
    <PageLayout
      data={data} title="Anglia Refinishing Services"
      description="Near Cromer, Norfolk"
    >
      <p>expert vehicle paint and body repairs</p>
      <div className="row">

        <div className="span5">
          <p><strong>Address:</strong></p>
          <address>
            Anglia Refinishing Services<br />
            Unit 6 stonehill way Holt Road<br />
            Cromer<br />
            Norfolk<br />
            NR27 9JW<br />
          </address>

          <span title="Phone"><strong>Phone:</strong> 01263 515 297</span><br />
		<span title="Website"><strong>Website:</strong><br />
				www.angliarefinishing.co.uk
				</span><br />
		<span title="Website"><strong>Twitter:</strong><br />
				</span><br />
        </div>

        <div className="span3">
          <img alt="Recommended site"
               src="/img/badges/recomm2-200.png"/><br />

        </div>
      </div>


    </PageLayout>
  );
};

export default Page;

export const query = graphql`
  query {
    profile: profileYaml {
      ...ProfileFragment
    }
    site {
      siteMetadata {
        title,
        showThemeLogo
      }
    }
  }
`
